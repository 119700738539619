export const credits = {
	assets: {
		paintcan: {
			title: "Paint Can",
			link: "https://poly.google.com/view/9pyBQ8e4tSy",
			authorName: "Jarlan Perez",
			by: "https://poly.google.com/user/4lZfAdz3x3X",
			license: "CC-BY"
		},
		bumblebee: {
			title: "Bumblebee",
			link: "https://poly.google.com/view/42S5XD_3C4O",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		beehive: {
			title: "beehive",
			link: "https://poly.google.com/view/3vDdEzkC9qd",
			authorName: "Workshop apelab",
			by: "https://poly.google.com/user/69d7kBI1hv-",
			license: "CC-BY"
		},
		helicopter: {
			title: "Low Polly Helicopter 3D model",
			link: "https://www.turbosquid.com/3d-models/helicopter-red-white-3d-model-1371592",
			authorName: "The Mad Man",
			by: "https://www.turbosquid.com/Search/Artists/The-Mad-Man",
			license: "Royalty Free License"
		},
		trafficCone: {
			title: "Traffic Cone",
			link: "https://poly.google.com/view/eFQ60hneKwL",
			authorName: "Adam Marc Williams",
			by: "https://poly.google.com/user/fGPNyCq3kfD",
			license: "CC-BY"
		},
		bulldozer: {
			title: "Bulldozer",
			link: "https://poly.google.com/view/ddxtaegI3HQ",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		grass: {
			title: "Grass",
			link: "https://poly.google.com/view/9S_Vjc9yqp0",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		pottedPlant: {
			title: "Plant",
			link: "https://poly.google.com/view/8KMKYzAqWjp",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		tree: {
			title: "Tree",
			link: "https://poly.google.com/view/6pwiq7hSrHr",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		mailbox: {
			title: "Mailbox",
			link: "https://poly.google.com/view/f_ndDRWiU0I",
			authorName: "Darwin Yamamoto",
			by: "https://poly.google.com/user/bV1kmv75wbM",
			license: "CC-BY"
		},
		paperAirplane: {
			title: "Paper airplane",
			link: "https://poly.google.com/view/75WQH5E29tF",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		surfboard: {
			title: "Surfboard",
			link: "https://poly.google.com/view/3js4cQ-O-p2",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		bicycle: {
			title: "Bicycle",
			link: "https://poly.google.com/view/axc03j3xKfz",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		microphone: {
			title: "Microphone",
			link: "https://poly.google.com/view/2cDNCSVfAfv",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		stand: {
			title: "Rolling Music Speaker Stand",
			link: "https://poly.google.com/view/2QYyKRb68QD",
			authorName: "Peter Simcoe",
			by: "https://www.simcoe.co.uk",
			license: "CC-BY"
		},
		target: {
			title: "Target Dummy v1.0 - Life Sized",
			link: "https://poly.google.com/view/5FIGgLx9SnR",
			authorName: "Eddie Balderas",
			by: "https://poly.google.com/user/2Wi2dCuWb-1",
			license: "CC-BY"
		},
		arrow: {
			title: "Arrow",
			link: "https://poly.google.com/view/6pMBOmBFxGt",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		chair: {
			title: "Office Chair",
			link: "https://poly.google.com/view/3txPAhYeu-x",
			authorName: "Ryan Donaldson",
			by: "https://poly.google.com/user/4iEQckaqJI6",
			license: "CC-BY"
		},
		cubicle: {
			title: "Cubicle",
			link: "https://poly.google.com/view/9oZP4harYcp",
			authorName: "Stephen Faett",
			by: "Stephen Faett",
			license: "CC-BY"
		},
		shuttle: {
			title: "Space Shuttle",
			link: "https://poly.google.com/view/djxolbz_CYC",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		vr: {
			title: "VR Headset",
			link: "https://poly.google.com/view/bvd33G7Q66m",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		burger: {
			title: "Cheeseburger",
			link: "https://poly.google.com/view/cAZTnehhBnx",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		laptop: {
			title: "Laptop",
			link: "https://poly.google.com/view/fEYeMIiRNHM",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		watercooler: {
			title: "Water Cooler",
			link: "https://poly.google.com/view/6zon-acGTIu",
			authorName: "Alex “SAFFY” Safayan",
			by: "https://poly.google.com/user/b7jif2AAit5",
			license: "CC-BY"
		},
		sword: {
			title: "Sword of Legend",
			link: "https://poly.google.com/view/94l5DEVQPM2",
			authorName: "Brandon Evans",
			by: "https://poly.google.com/user/8qz4WdIJ9VC",
			license: "CC-BY"
		},
		books: {
			title: "Book Stack",
			link: "https://poly.google.com/view/1WggoIFq8tx",
			authorName: "Danny Bittman",
			by: "https://poly.google.com/user/axCOZrx3oD3",
			license: "CC-BY"
		},
		barbell: {
			title: "weighted Barbell",
			link: "https://poly.google.com/view/defq8qotcwX",
			authorName: "Jarlan Perez",
			by: "https://poly.google.com/user/4lZfAdz3x3X",
			license: "CC-BY"
		},
		road: {
			title: "Road",
			link: "https://poly.google.com/view/79YnTuHPrZN",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
		airplane: {
			title: "Airplane",
			link: "https://poly.google.com/view/8ciDd9k8wha",
			authorName: "Poly by Google",
			by: "https://poly.google.com/user/4aEd8rQgKu2",
			license: "CC-BY"
		},
	},
	boilerplate: {
		express: {
			title: "React Production Deployment",
			link: "https://github.com/esausilva/react-production-deployment/tree/master/heroku",
			authorName: "Esau Silva",
			by: "https://github.com/esausilva",
			license: "MIT"
		},
		three: {
			title: "Starting with React 16 and Three.js in 5 minutes",
			link: "https://blog.bitsrc.io/starting-with-react-16-and-three-js-in-5-minutes-3079b8829817",
			authorName: "Alexander Solovyev & Marina Vorontsova",
			by: "https://github.com/supromikali",
			license: "MIT"
		}
	}
};

